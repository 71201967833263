@hsnet-green: #4c8c2b;
@results-info-gray: #373a36;

@screen-sm-min: 768px;
@screen-md: 992px;
@screen-md-min: @screen-md;
@screen-sm-max: (@screen-md - 1);

@import "services";
@import "map";

body {
	background-color: #fff;
	background-image: url("/assets/img/intro.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 100%;
}

.navbar .row {
	margin: 0 !important;
}

/** Desktop Search Chrome **/

.logo-div {
	max-width: 500px;
	max-height: 30px;
}

.logo-text {
	margin-top: 50px;
	font-size: 18px;
	margin-left: 12px;
	@media only screen and (min-width: 768px ) {
		margin-left: 30px;
	}
}

.logo-img {
	height: 25px;
	top: 15px;
	z-index: 1;
	left: 12px;
	position: absolute;
	@media only screen and (min-width: 768px ) {
		left: 30px;
		height: 30px;
		top: 12px;
	}
}

.logo-askizzy {
	max-width: 140px;
	max-height: 20px;
	margin-left: 3px;
}


.input-sm {
	height: 35px;
	font-size: 15px;
}

.services-search-row {
	background-color: #133880;
	height: 120px;
	min-width: 1024px;

	> div {
		float: left;
	}

	.services-search-row-main {
		position: relative;
		margin-left: 3%;
		height: 120px;
		float: left;
		width: 70%;
		padding-top: 18px;

		form {
			position: relative;
			display: grid;
			grid-template-columns: minmax(auto, max-content) minmax(auto, max-content) auto;
			float: left;
			margin-top: 9px;
			width: 100%;
			button, input, span.twitter-typeahead {
				display: block;
				height: 42px;
				float: left;
			}
			button {
				width: 170px;
			}
			span.twitter-typeahead {
				background-color: white;
			}

			input[type="text"] {
				width: 100%;
			}
		}
		.help-block {
			padding-top: 3px;
			color: white;
			clear: left;
		}
	}

	.services-search-row-auxiliary {
		float: right;
		border-left: 1px solid #0c2661;
		width: 27%;
		.btn-auxiliary {
			width: 50%;
			float: left;
			height: 120px;
			padding: 0 0 0 0;
			border-width: 0 0 0 0;
			.inner {
				margin-left: 52px;
				.text {
					width: 75px;
					float: left;
					margin-left: 15px;
					text-align: left;
				}
				i.fa {
					font-size: 30px;
					display: block;
					float: left;
				}
			}
		}
	}
}

#totalRecords_container {
	min-height: 49px;
	background-color: @results-info-gray;
	padding-top: 12px;
	padding-right: 0;
	padding-left: 15px;
	padding-bottom: 0;
	font-size: 18px;
	font-weight: 600;
	color: white;
}

#pagination {
	background-color: white;
	text-align: right;
	padding: 0px 32px;
}

.down-arrow {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	z-index: 502;
	cursor: default;
	border-top: 15px solid @results-info-gray;
}

#footer {
	margin-top: 0 !important;
}

#wrap {
	min-height: inherit !important;
	margin: 0 auto !important;
	padding: 0 !important;
}

/** Search Results **/

#search-sidebar {
	ul.pagination {
		margin-top: 7px;
		margin-bottom: 7px;

		> li > a {
			padding-right: 0px;
			padding-left: 26px;

			&:focus {
				text-decoration: underline;
			}
		}
	}

}

#entityViewList {
	.alert {
		margin-bottom: 0;
		.alert-link {
			color: #843534 !important;
			text-decoration: underline;
			font-weight: normal;
		}
	}
}

.entity-list-item {
	border: 0px solid transparent !important;
	border-bottom: 4px solid #999 !important;
	margin-bottom: 0px !important;
	box-shadow: none !important;
}

.panel-heading {
	padding-top: 8px !important;
	padding-bottom: 6px !important;
}

.green-heading {
	background-color: @hsnet-green !important;
}

.hsnet_contrast {

	.map-info-overlay {
		background-color: darken(@hsnet-green, 10%) !important;
	}

	.green-heading,
	.directory-details-body-tabs > li > a {
		background-color: darken(@hsnet-green, 10%) !important;
	}

	.advanced-search-modal .modal-header .close-modal {
		color: #666 !important;
	}

	.directory-details-body-tabs {
		.active > a,
		.active > li,
		a:hover,
		li:hover,
		a:focus,
		li:focus {
			background-color: darken(@hsnet-green, 20%) !important;
		}
	}

	.label-round.label-success {
		border: 1px solid black;
		color: black;
		padding: 3px 16px;
	}

}

a.crisis {
	display: block;

	&:hover {
		text-decoration: none;
	}

	div {
		color:white;
	}

	.crisis-heading:hover {
		background-color: #007ead !important;
	}

	span.faux-link:hover {
		text-decoration: underline;
	}
}

.crisis-heading {
	background-color: #00a3e0 !important;
	border-bottom: none !important;
	padding-top: 13px !important;
	padding-bottom: 15px !important;

	.crisis-name {
		font-size: 18px;
		font-weight: 600;
	}

	.crisis-service {
		font-weight: 600;
	}

	.phone-link {
		font-size: 18px !important;
		font-weight: 400;
		line-height: 20px;

		.fa {
			margin: 0 0 0 6px;
			font-size: 18px !important;
		}
	}

	.entity-details-view-link {
		line-height: 25px;
		color: #fff;
	}

	.more-info-row {
		padding-top: 10px;
	}
}

.green-heading > a,
.crisis-heading > a,
{
	color: white !important;
	text-decoration: none;
	font-weight: 600;
}

.entity-list-item .dl-horizontal > dt {
	text-align: left !important;
	width: 24px !important;
	margin-bottom: 3px !important;
}

.entity-list-item .dl-horizontal > dd {
	text-align: left !important;
	margin-left: 20px !important;
	margin-bottom: 3px !important;
}

.entity-list-item .panel-body .fa {
	font-size: 18px !important;
}

.entity-list-item .fa-map-marker {
	padding-right: 3px !important;
}

.entity-list-item,
.entity-list-item-mobile {

	.atsi-flag img {
		height: 20px;
		width: 20px;
	}

}

.phone-link {
	color: #333 !important;
}

.leaflet-sidebar {
	width: 615px;
	> .leaflet-control {
		box-shadow: none;
		-webkit-border-radius: 0;
		border-radius: 0;
	}
}

a.leaflet-popup-close-button:focus {
	text-decoration: underline;
}

/** Search Spinner **/

.search-spinner {
	display: none;
	color: white;
	padding-top: 9px;
	padding-right: 0px;
	padding-left: 20px;
}

/** advanced search **/

.service-directory-modal {
	width: 775px !important;

	.modal-header {
		border-bottom: none;
		padding: 15px 15px 0px 15px;

		> h1 {
			letter-spacing: 0px;
			padding-left: 52px;
			padding-top: 20px;
		}

		.close-modal {
			font-size: 24px !important;
			color: #DDD !important;
			cursor: pointer !important;
		}

		.close-modal:hover {
			color: #333 !important;
		}
	}

	.modal-body {
		padding: 20px 20px 50px;
	}

	.advanced-search-select {
		border-radius: 4px !important;
		padding: 5px 8px;
	}

	.custom-col-sm-2 {
		width: 18% !important;
		padding-right: 5px;
	}

	.custom-col-sm-3 {
		width: 18% !important;
		padding-left: 5px;
		padding-right: 5px;
	}

	.custom-col-sm-4 {
		padding-left: 10px;
	}

	.custom-col-sm-5 {
		width: 36% !important;
		padding-left: 5px;
		padding-right: 5px;
	}

	.custom-col-sm-7 {
		padding-left: 10px;
	}

	.custom-col-sm-11 {
		width: 94.666667%;
		padding-right: 18px;
	}

	.custom-col-sm-12 {
		padding-right: 5px;
	}

	.italic {
		font-style: italic;
		color: #000;
	}

	.clear-advanced-search {
		width: 164px;
	}

	.btn-group >.btn {
		float: right !important;
	}

	.modal-checkbox {
		margin-top: 10px;
	}

	/* width auto by when non mobile
		override possible redundant width setting
		by mobile css below
	*/
	.service-type-input-picker {
		~ div.dropdown-menu {
		width: auto !important;
		}
	}
}

/* The !importants are required because this is a
dynamically created control via the bootstrap-select
component which sets the css classes in its own way
*/
.service-type-input-picker {
	border: 1px solid #ccc !important;

	&:hover, &:focus {
		color: #555 !important;
	}

	~ div.dropdown-menu {
		width: 100%;
	}

}

#spokenLanguagesInput {
	height: 30px;
	padding: 5px 10px;
	font-size: 14px;
	line-height: 1.5;
	width: 228px;
}

.modal-backdrop.fade.in {
	opacity: 0.85;
	filter: alpha(opacity=85);
}

/** Service Types **/

.service_types_container {
	border-top: 1px solid #eee;

	.just-padding {
		padding: 15px;
	}

	.service-type-label {
	  display: block;
	  float: left;
	  width: 85%;
	}

	.service-type-chevron {
	  display: block;
	  float: left;
	}

	.badge {
	  &:hover, &:focus {
		background-color: #3183CE;
	  }
	  // Required for mozilla to not wrap badge items
	  display:flex;
	  flex-wrap: wrap;
	  pointer-events: auto;
	  line-height: 14px;
	}

	.badge-div {
	  padding-left: 0;
	}

	// Not clickable if there are no services
	.badge.no-services {
	  pointer-events: none;

	  &:hover, &:focus {
		background-color: #999;
	  }

	  .goto-services-arrow {
		display:none;
	  }
	}

	.list-group.list-group-root {
		padding: 0;
		margin-bottom: 15px;
		overflow: hidden;

		// Visually indicate the list item the user clicked
		// through to view results
		.last-clicked {
		  .badge:not(:hover){
			background-color: #133175;
		  }
		  background-color: #f5f5f5;
		}
	}

	.list-group.list-group-root .list-group {
		margin-bottom: 0;
		margin-left: 10px;
	}

	.list-group.list-group-root .list-group-item {
		border-radius: 0;
		border-width: 1px 0 0 0;
	}

	.list-group.list-group-root > div .list-group-item:first-child {
		border-top-width: 0;
	}

	.list-group-item .glyphicon {
		margin-right: 7px;
	}

	.list-group-item.no-children {
		.glyphicon-chevron-right {
		  visibility: hidden;
		}
		pointer-events: none;
	}

	.service-type-heading {
	  padding-left: 20px;
	  padding-top: 5px;
	  padding-bottom: 5px;
	}

	.goto-services-arrow {
	  font-size: 13px;
	  margin-left: 5px;
	}
}

/** Details View **/

.directory-details-controls {
	.fa {
		position: relative;
		top: 3px;
		font-size: 18px;
		margin-right: 6px;
	}
	a {
		height: 80px;
		background-color: #333;
		color: white !important;

		font-size: 14px;
		font-weight: 500;
		line-height: 2;
		text-align: center;
		padding-top: 24px;

	}
	a:hover {
		background-color: #282828;
		text-decoration: none;
	}
}

.directory-details-controls-print {
	border-right: 2px solid #282828;
}

.directory-details-top {
	padding-top: 15px;
	padding-bottom: 15px;
}

.backToResultsListLink {
	color: black !important;
	text-decoration: none !important;
	padding-left: 30px;

	&:hover, &:focus {
		text-decoration: underline !important;
	}

	.fa {
		color: #00a3e0 !important;
		margin-right: 2px;
	}
}

.backToServiceTypesListLink {
	color: black;
	text-decoration: none !important;
	padding-left: 15px;

	&:hover, &:focus {
	  text-decoration: underline !important;
	}

	.fa {
		color: #00a3e0;
		margin-right: 2px;
	}
}

#as-mobile .backToServiceTypesListLink {
	margin-left: 10px;
}

.directory-details-last-updated {
	padding-right: 30px;
	font-weight: 400;
	font-size: 14px;
	color: #555555;
}

.directory-details-header {
	padding-left: 30px !important;
	font-size: 24px;
	font-weight: 400;
	margin-bottom: 10px;
}

.header-flags {
	margin-bottom: 15px;

	.flag-container {
		margin-left: 15px;
		.atsi-flag {
			width: 35px;
			height: 35px;
			display: inline-block;
		}
	}
}

.directory-details-info-row {
	margin: 0;
	border-bottom: 1px solid #d3d3d3;
	padding: 9px 0px 4px 0px;
	font-size: 12px;
	dt {
		font-weight: 600;
		text-transform: capitalize;
		width: percentage(1/3);

		&.directory-details-phone-tty {
			text-transform: uppercase;
		}
	}
	dd {
		width: percentage(2/3);

		@media only screen {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	dd, dt {
		padding-bottom: 3px;
		line-height: 18px;
	}

	.opening-hours-info {
		font-size: 18px;
	}
}

.is-desktop {

	#entityViewList {
		background: #999;
	}

	.directory-details-info-row {
		margin: 0 30px;
		font-size: 14px;
	}
}

.directory-details-body {
	.tab-content {
		border-left: 6px solid LightGray;
		border-right: 6px solid LightGray;

		dt {
			text-align: left;
			padding-right: 14px;
		}
		dd {
			padding-left: 15px;
		}
		.row {
			margin: 0;
		}
		h3.visible-print {
			text-align: center;
			font-size: 18px;
		}
	}
}


.is-desktop {
	.directory-details-body {
		.tab-content {
			dt {
				text-align: right;
			}
			dd {
				padding-left: 0px;
			}
			.row {
				padding-left: 15px;
				margin: 20px;
			}
			border-left: none;
			border-right: none;
		}
	}
}


.directory-details-label {
	margin-top: 24px;
	margin-bottom: 10px;
	padding-right: 30px !important;
	text-align: right;
	line-height: 2;
	span.label {
		font-size: 14px;
	}
}

.directory-details-body-tabs {
	margin-left: 0;
	margin-right: 0;
	font-size: 14px;

	a,
	li,
	{
		background-color: #4c8c2b;
		color: #fff !important;
		border: none !important;
		text-align: center;
	}

	a {
		padding-top: 11px !important;

		&:focus {
			text-decoration: underline !important;
		}
	}

	li {
		padding: 0 !important;
		margin: 0 !important;
	}

	li:first-child {
		border-right: 1px solid #3f7c11 !important;
	}

	li:last-child {
		border-left: 1px solid #3f7c11 !important;
	}

	.active > a,
	.active > li,
	a:hover,
	li:hover,
	a:focus,
	li:focus {
		background-color: #396920 !important;
		color: #fff !important;
	}
}

table.opening_hours {
	td {
		padding-right: 4px;
	}
}

/** Labels **/
.label-round {
	border-radius: 14px !important;
	background-color: #fff !important;
	font-weight: 500 !important;
	text-transform: capitalize;

	&.label-success {
		border: 1px solid #579337;
		color: #579337;
		padding: 3px 16px;
	}
}

/** Print **/
@media print {
	@page {
		size: portrait;
	}

	marquee {
		-moz-binding: none;
	}

	body {
		overflow: visible !important;
	}

	#search-sidebar {
		width: 100% !important;
		overflow: visible;
		height: auto !important;
	}

	.leaflet-sidebar {
		position: static !important;
		width: 100% !important;
	}

	#leaflet-map {
		padding: 0 !important;
		height: auto !important;
	}

	.app-role-heading,
	.app-role-heading a,
	.app-role-heading span,
	.leaflet-google-layer div,
	.services-search-row,
	.leaflet-top,
	.leaflet-bottom,
	.leaflet-popup-pane,
	.leaflet-objects-pane,
	.directory-details-controls,
	.directory-details-top,
	#footer,
	.directory-details-body-tabs,
	.navbar-brand.logo-nav::after,
	.navbar-primary
	{
		display: none !important;
	}

		.tab-pane {
			display: block !important;
			overflow: visible !important;
			page-break-inside: avoid;
		}
}

/** Mobile Search **/

.search-box-mobile {
    padding: 20px 10px 1px;
    line-height: 0;

	background: #f7f7f7; /* Old browsers */
	background: -moz-linear-gradient(top,  #f7f7f7 0%, #eaeaea 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f7f7f7), color-stop(100%,#eaeaea)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #f7f7f7 0%,#eaeaea 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #f7f7f7 0%,#eaeaea 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #f7f7f7 0%,#eaeaea 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #f7f7f7 0%,#eaeaea 100%); /* W3C */

	.input-group > span:not(:first-of-type) input {
		border-top-width: 0px;
	}

	.input-group-btn > .btn {
		margin-left: 10px;
	}
}

.menu-tabs-mobile {
	a,
	button,
	li,
	{
		background-color: #4d4d4d;
		color: #fff !important;
		border: none !important;
		text-align: center;
		text-align: -webkit-center;
		vertical-align: middle !important;
	}

	#iss-search_submit {
		padding: 8px 18px;
	}

	li {
		padding: 0 !important;
		margin: 0 !important;
		border-right: 1px solid #282828 !important;
		float: left;
		width: 50%;
	}

	li:last-child {
		border-right: none;
	}

	.active > a,
	.active > li,
	a:hover,
	li:hover,
	a:focus,
	li:focus,
	{
		background-color: #333 !important;
		color: #fff !important;
	}


}

.hide-search-results-lnk .menu-tabs-mobile {
	display: none !important;
}

.top-panel-mobile {
	width: 100%;
	height: 64px;
	background-color: #133880;

	.mobile-logo-bar {
		margin-bottom: 12px;
	}

	.back-menu-mobile {
		margin-top: 0%;
		position: relative;
		display: block;
		height: 40px;
		padding-top: 20px;
		width: 50%;
	}

	.logo-img {
		display: none;
	}
}

.map-locate-me {
	background-color: #00a3e0;
	color: #fff;
	border-radius: 25px;
	height: 45px;
	width: 45px;
	cursor: pointer;
	text-align: center;
	display: table;
	font-size: 23px;
	margin-left: 10px !important;
	margin-bottom: 25px !important;

	&:hover {
		background-color: #008ecb;
	}

	.fa {
		display: table-cell;
		vertical-align: middle;
	}
}

.is-desktop {
	.leaflet-sidebar.left.visible ~ .leaflet-left {
		left: 600px;
	}
}

.back-menu-mobile {
	color: #fff;
	text-decoration: none !important;
	padding-left: 18px;

	.fa-arrow-left {
		color: #00a3e0;
		padding-right: 2px;
	}
}

.entity-list-item-mobile {
	width: 100%;
	border: 6px solid LightGray;
	border-bottom: none;
	min-height: 80px;
	font-size: 13px !important;
	padding: 12px;

	.row {
		margin-bottom: 2px !important;
	}

	.title-dark {
		font-size: 14px !important;
		color: #333;
			font-weight: 600;
	}

	.title-light {
		font-size: 14px !important;
		color: gray;
		font-weight: 600;
	}

	.description {
		margin: 4px 0px;
	}

	.fa {
		font-size: 14px !important;
	}

	.dl-horizontal {
		margin: 6px 0px;
	}

	.dl-horizontal > dt {
		text-align: left !important;
		width: 24px !important;
		float: left;
	}

	.dl-horizontal > dd {
		text-align: left !important;
		margin-left: 20px !important;
		margin-bottom: 3px !important;
	}

	.entity-details-view-link {
			display: block;
			padding: 7px 0;
			font-size: 14px;
		}

	.flag-container {
		margin-bottom: 10px;
	}
}

.phone-link {
	background-color: #CCEDF9;
	color: #00a3e0 !important;
	font-size: 12px;
	width: 180px !important;
	text-align: left;

	.fa {
		padding-right: 10px;
	}
}

.is-desktop {
	.phone-link {
		background-color: inherit !important;
		color: #0a0a0a !important;
		font-size: 14px;
	}

	.crisis-heading .phone-link {
		color: white !important;
	}

	.now-open-crisis {
		i.fa {
			padding-right: 10px;
		}

		text-align: right;
		margin-right: 15px;
		font-size: 18px;
	}
}

#advancedSearchMobileContainer {
	position: relative;
	z-index: 10;

	.form-group {
		margin-left: 3px;
		margin-right: 3px;
	}

	#wordFilterTypeSelect {
		margin-bottom: 4px;
	}

	.advanced-search-title {
		padding: 12px 0px 12px 18px;
		font-size: 18px;
	}

	.control-label {
		padding-top: 0px;
		padding-bottom: 5px;
	}

	.italic {
		font-style: italic;
		color: #000;
		font-size: 12px;
	}

	#spokenLanguagesInput {
		width: 100%;
	}

	.form-buttons {
		padding-top: 20px;
		padding-bottom: 45px;
		width: 100%;

		> button {
			width: 33%;
			font-size: 14px !important;
			height: 45px;
		}

		.fa {
			padding-right: 5px;
		}

		.cancel-advanced-search {
			border-left: 1px solid #007ead;
		}
	}
}

.entity-details-mobile {
	.directory-details-info-row {
		dt, dd {
			width: 100%;
		}
	}

	.directory-details-body-tabs {
		margin-left: 0px;
		margin-right: 0px;
	}

	.nav-pills, .nav-tabs {
		border-left: 0;
	}
}


/** mobile spinner **/

.mobile-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(64, 64, 64, 0.5);
	z-index: 10000;
}

.mobile-spinner {
	padding: 0;
	padding-top: 80%;
	padding-left: 38%;
	display: none;

	.fa-refresh {
		font-size: 48px;
		filter:alpha(opacity=1);
		-moz-opacity:1;
		-khtml-opacity: 1;
		opacity: 1;
	}
}

.generic-modal {
		padding: 10px;

		.close-modal {
			font-size: 24px !important;
			color: #DDD !important;
			cursor: pointer !important;
		}

		.close-modal:hover {
			color: #333 !important;
		}

		.modal-header {
			border-bottom: none;
			padding: 10px;
		}

		.modal-title {
			letter-spacing: 0px;
			padding-left: 18px;
		}
}

.share-modal {
	padding: 10px;

	.modal-dialog {
		width: 420px;
	}

	.close-modal {
		font-size: 24px !important;
		color: #DDD !important;
		cursor: pointer !important;
	}

	.form-control {
		height: auto; // Fix issue with button-addon not at correct height.
	}

	.share-button-container {
		margin-bottom: 20px;
	}

	.share-button {
		display: inline-block;
		height: 80px;
		width: 80px;
		padding: 10px;
		margin: 0 20px 20px 20px;

		i {
			width: 60px;
			height: 60px;
			font-size: 40px;
			text-align: center;
			background-color: rgba(237, 243, 255, 0.9);
			border: 1px solid rgba(237, 243, 255, 0.9);
			padding: 10px;
			border-radius: 10px;
		}

		.label {
			display: block;
			margin: 10px auto;
			padding: 0;
			color: #333;
		}

		&:hover {
			cursor: pointer;
			i {
				background-color: rgba(237, 243, 255, 1.0);
				border: 1px solid #3183CE;
			}

			.label {
				color: #3183CE;
			}

		}

	}

	.share-copy-link {

		position: relative;

		&:before {
			content: "Copied!";
			position:absolute;
			right: 0;
			bottom: 120%;

			width: 100px;
			padding: 5px;

			background:#000;
			color: #fff;
			text-align: center;

			opacity: 0;
			transition: .3s opacity;
		}

		&.copied:before, &.copied:after {
			opacity: 1;
		}

		&:after {
			content: "";
			position:absolute;
			right: 45px;
			top: 0;
			transform: translateY(-6px);
			z-index: 100;

			border: 5px solid #000;
			border-color: black transparent transparent transparent;

			opacity: 0;
			transition: .3s opacity;
		}

	}
}

.no-results-container {
	height: 270px;
	padding-right: 10px;

	.fa-info-circle {
		font-size: 50px;
		margin-top: 17px;
		margin:17px 0 10px 0;
	}

	li {
		padding: 2px !important;
	}
}

.entity-list-totals {
	height: 0px;

	.share-search {
		font-size: 14px;

		&:hover {
			cursor: pointer;
		}
	}

}

.custom-field-error .form-control {
	background-color: #fdf7f7;
}

#PURModal textarea.form-control {
	height: 9em;
}

.is-desktop button.submitPUR {
	float: right;
}

#PURForm {
	.pur-spinner {
		display: none;
		padding-top: 14px;
		margin-right: 10px;
		float: right;
		color: #00A3E0;
	}
}

.is-mobile {
	.crisis {
		font-size: 12px;
		border: 6px solid LightGray !important;
		border-bottom: none !important;
		text-decoration: none;

		.crisis-heading .crisis-name {
			font-size: 14px !important;
		}

		.entity-details-view-link {
			font-size: 11px;
		}

		.crisis-service {
			font-size: 12px !important;
		}

		.crisis-heading .phone-link {
			padding: 2px 12px;
		}

		.now-open-crisis {
			i.fa {
				padding-right: 5px;
			}
			text-align: right;
			margin-right: 15px;
			font-size: 14px;
		}
	}
}

.related-entities {

	.fa-refresh {
		margin-top: 10px;
		margin-left: 50%;
		color: @hsnet-green;
	}

	.col-md-8 {
		width: 65.666667% !important;
	}
}

.mobile-logo-bar {
	background: white;
	height: 22px;

	img {
		display: block;
		height: 20px;

		&.logo-bar-hsnet-logo {
			float: left;
			margin-left: 12px;
		}

		&.logo-bar-nsw-logo {
			float: right;
			margin-right: 12px;
		}
	}
}

.gm-style a:focus,
.gm-style a:focus img,
{
	outline: 2px solid #999;
}


.form-control {
	color: #000;
}

/* do not group these rules*/
.form-control::-moz-placeholder {
	color: #333333;
}
.form-control:-ms-input-placeholder {
	color: #333333;
}
.form-control::-webkit-input-placeholder {
	color: #333333;
}

.tt-suggestion.tt-is-under-cursor {
	background-color: #404040;
}

.show-suggestions-link {
	display: none;
	position: absolute;
	top: 12px;
	right: 220px;
	background-color: white;
	padding: 1px;
}

#mapMobileContainer {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.search-box-mobile {

	.search-services-box,
    .search-locations-box {
		height: 32px;
		width: 100%;
		background-color: #fff !important;
		font-size: 1.7rem;
	}

	.twitter-typeahead {
		display: inline;
		width: 100%;
	}

	.tt-suggestion	{
		padding: 10px 12px;
	}

}

.intro-page-mobile {
	h3 {
		font-weight: 300;
		font-size: 1em;
		margin: 10px;
	}
}

// Add service form
.required {
	color: #b94a48;
}

.add-formset {
	margin-top: 15px;
}

.panel-collapse .collapse {
	padding-left: 15px;
}

@media only screen and (max-width: 1023px) {
	.services-search-row {
		min-width: auto;
		height: 165px;
	}

	.services-search-row-auxiliary {
		.btn-auxiliary {
			.text {
				display: none;
			}
		}
	}
}

@import "theme-custom";
