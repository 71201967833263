#leaflet-map {
	.map-top-shadow {
		box-shadow: inset 0px 5px 5px -3px rgba(33, 33, 33, 0.4);
		cursor: default;
		height: 5px;
		left: 0px;
		position: absolute;
		top: 0px;
		width: 100%;
		z-index: 501;
	}
	.map-info-overlay {
		background-color: #4c8c2b;
		color: white;
		cursor: default;
		left: 0px;
		position: absolute;
		top: 0px;
		z-index: 500;
		padding-left: 20px;
		margin-lefT: 10px;
		padding-bottom: 20px;
		width: 582px;

		.info-container {
			font-size: 50px;
			margin-top: 17px;
		}
		p.map-message {
			font-size: 24px;
			line-height: 28px;
			margin-top: 8px;
			width: 90%;
		}
		p.map-direction-line {
			font-size: 16px;
			font-weight: bold;
			margin-top: 23px;
		}
	}

	.map-down-arrow {
		width: 0;
		height: 0;
		border-left: 20px solid transparent;
		border-right: 20px solid transparent;
		z-index: 502;
		position: absolute;
		top: 0;
		left: 281px;
		cursor: default;
		border-top: 20px solid #133880;
	}
	z-index: 1;
	width: 100%;
	height: 455px;
	#search-sidebar {
		height: 455px
	}
}

.leaflet-control-attribution.leaflet-control {
	display: none;
}
