#services-search {
	vertical-align: middle;
}

/* Fix for strange extra scrollable width problem */
#services-search .row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.pagination {
	cursor: pointer;
}

.pagination .active {
	cursor: default;
}

.directory-details-body dl > dt {
	text-transform: capitalize;
}

#search-sidebar {
	width: 585px;
	overflow: auto;
	height: 555px;
	margin-left: 15px;
	padding: 0;
	@media (min-height: 800px) {
		height: 575px
	}
	@media (min-height: 900px) {
		height: 675px
	}
}

.leaflet-sidebar {
	padding: 0;
	display: none;
}
